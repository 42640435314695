import loadable from '@loadable/component'

const routes = [
  {
    path: '/',
    exact: true,
    component: loadable(() => import('@/view/index/index')),
  },
  {
    path: '/indexDetail',
    exact: true,
    component: loadable(() => import('@/view/indexDetail/index')),
  },
  {
    path: '/course-center',
    exact: true,
    component: loadable(() => import('@/view/course-center/course-center')),
  },
  {
    path: '/about-us',
    exact: true,
    component: loadable(() => import('@/view/about-us/about-us')),
  },
  {
    path: '/course-detail',
    exact: true,
    component: loadable(() => import('@/view/course-detail/course-detail')),
  },
  {
    path: '/commonProblems',
    exact: true,
    component: loadable(() => import('@/view/commonProblems/index')),
  },
]

export default routes
